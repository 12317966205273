import Vue from 'vue';
import $http from './helpers/api';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './assets/scss/tailwind.scss';
import './assets/scss/composed.scss';
import {
  checkAuth, userSessionExists, removeSession, redirectTo, setSession,
} from './helpers/auth';

import App from './App';

Vue.component('ErrorLayout', () => import(/* webpackChunkName: "error" */ '@/layouts/Error.vue'));
Vue.component('CenterLayout', () => import(/* webpackChunkName: "auth" */ '@/layouts/Center.vue'));
Vue.component('DashboardLayout', () => import(/* webpackChunkName: "dashboard" */ '@/layouts/Dashboard.vue'));

Vue.config.productionTip = false;

$http.interceptors.request.use(async (config) => {
  const bearer = store.getters['auth/getBearerToken']
    || store.getters['auth/getRefreshToken'];
  if (bearer) {
    config.headers = { Authorization: `Bearer ${bearer}` };
  }

  const shouldRefresh = userSessionExists() && store.state.auth.accessTokenExpiry
    - new Date().getTime() <= Number.parseInt(process.env.VUE_APP_REFRESH_TIME, 10)
    && !store.state.auth.refreshing;

  if (shouldRefresh && config.url !== 'accounts/login') {
    store.commit('auth/setRefreshing', true);
    store.commit('auth/setBearer', false);
    const { accessToken, accessTokenExpiry } = await store.dispatch('auth/refreshTokens');
    setSession(accessToken, accessTokenExpiry);
    config.headers = { Authorization: `Bearer ${accessToken}` };
    store.commit('auth/setRefreshing', false);
  }

  return config;
});

$http.interceptors.response.use(async (response) => response,
  (error) => {
    if (error.response.data.statusCode === 401 && userSessionExists()) {
      store.commit('auth/removeAccessToken');
      removeSession();
      router.push({ name: 'Login' });
    }
    store.commit('auth/setRefreshing', false);
    return Promise.reject(error);
  });

router.beforeEach((to, from, next) => {
  store.commit('AlertModule/setAlert');
  if (to.meta.only && store.state.auth.accountType !== to.meta.only) next({ name: 'RoomIndex' });
  if (to.fullPath === '/') next({ name: redirectTo() });
  checkAuth(to, from, next);
});

router.afterEach((to) => {
  document.title = to.meta.title || 'Ava Webinar';
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
