import Vue from 'vue';
import VueRouter from 'vue-router';

// Model-related routes
import roomRoutes from './rooms';
import presenterRoutes from './presenters';
import accountRoutes from './accounts';
import scopeRoutes from './scopes';
// import { redirectTo } from '../helpers/auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Login.vue'),
    meta: {
      auth: null,
      layout: 'CenterLayout',
    },
  },
  ...roomRoutes,
  ...presenterRoutes,
  ...accountRoutes,
  ...scopeRoutes,
  // { path: '/', redirect: { name: redirectTo() } },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      auth: true,
      layout: 'DashboardLayout',
    },
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "errors" */ '../views/errors/404.vue'),
    meta: {
      auth: false,
      layout: 'ErrorLayout',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
