const accountRoutes = [
  {
    path: '/accounts',
    name: 'AccountIndex',
    component: () => import(/* webpackChunkName: 'accounts' */ '../views/accounts/Index.vue'),
    meta: {
      auth: true,
      only: 'SUPER',
      title: 'Account listing',
      loadingDialog: false,
      layout: 'DashboardLayout',
    },
  },
  {
    path: '/accounts/create',
    name: 'AccountCreate',
    component: () => import(/* webpackChunkName: 'accounts' */ '../views/accounts/Create.vue'),
    meta: {
      auth: true,
      only: 'SUPER',
      title: 'Account creation',
      loadingDialog: false,
      layout: 'DashboardLayout',
    },
  },
  {
    path: '/accounts/:accountId',
    name: 'AccountUpdate',
    props: true,
    component: () => import(/* webpackChunkName: 'accounts' */ '../views/accounts/Update.vue'),
    meta: {
      auth: true,
      only: 'SUPER',
      title: 'Update an Account',
      loadingDialog: false,
      layout: 'DashboardLayout',
    },
  },
];

export default accountRoutes;
