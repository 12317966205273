import { findIndex } from 'lodash-es';
import $http from '../helpers/api';

const RoomsModule = {
  namespaced: true,

  state: () => ({
    pristine: true,
    loading: false,
    roomData: {
      data: [],
      total: 0,
      hasDefault: false,
    },
  }),

  getters: {
    getAllRooms: (state) => state.roomData.rooms,
    getRoomById: (state) => (roomId) => state.roomData.data.find(
      (room) => room.id === roomId,
    ) || false,
    getRoomsByName: (state) => (name) => state.roomData.data.find((room) => room.name === name),
  },

  mutations: {
    muddlePristine: (state) => { state.pristine = false; },
    setRooms: (state, res) => {
      state.roomData = { ...res };
    },
    appendRoom: (state, room) => state.roomData.data.push(room),
    removeRoom: (state, id) => {
      const index = findIndex(state.roomData.data, ['roomId', id]);
      if (index > -1) state.roomData.data.splice(index, 1);
    },
    replaceRoom: (state, newRoom) => {
      const index = findIndex(state.roomData.data, ['roomId', newRoom.roomId]);
      if (index > -1) state.roomData.data[index] = newRoom;
    },
  },

  actions: {
    /**
     * [GET] /admin/rooms
     *
     * @returns Room[]
     *
     * Gets a listing of the room resources available.
     * Sets them in internal storage and muddles the pristine flag.
     * If !pristine the view will rely on the storage data and
     * this method will not be called.
     */
    fetchRooms: ({ commit }, params) => new Promise((resolve, reject) => {
      $http.get('admin/rooms', { params })
        .then((res) => {
          commit('setRooms', res.data);

          commit('muddlePristine');
          resolve();
        })
        .catch((err) => reject(err));
    }),

    /**
     * [GET] /admin/rooms/:roomId
     *
     * @parameters roomId: string
     * @returns Room
     *
     * Fetches the requested Room from database.
     */
    fetchRoom: async ({ getters }, roomId) => {
      const room = getters.getRoomById(roomId);

      const queryRoom = () => new Promise((resolve, reject) => {
        $http.get(`admin/rooms/${roomId}`)
          .then((res) => resolve(res.data))
          .catch((err) => reject(err));
      });

      return room || queryRoom(roomId);
    },

    /**
     * [DELETE] /admin/rooms/:roomId
     *
     * @parameters roomId: string
     * @returns Room
     *
     * Deletes the specified Room from database and also
     * from local stoge.
     */
    deleteRoom: ({ commit }, roomId) => {
      commit('toggleLoading', null, { root: true });
      return new Promise((resolve, reject) => {
        $http.delete(`admin/rooms/${roomId}`)
          .then((res) => {
            commit('removeRoom', roomId);
            resolve(res);
          })
          .catch((err) => reject(err))
          .finally(() => commit('toggleLoading', null, { root: true }));
      });
    },

    /**
     * [POST] /admin/rooms
     *
     * @data CreateRoomRequestDto
     * @returns Room
     *
     * Creates a new Room in the storage, then
     * returns the created Room.This Room will then be
     * saved in the local storage.
     */
    createRoom: ({ commit }, roomData) => {
      commit('toggleLoading', null, { root: true });
      return new Promise((resolve, reject) => {
        $http.post('admin/rooms', roomData)
          .then((res) => {
            commit('appendRoom', res.data);
            resolve(res.data);
          })
          .catch((err) => reject(err))
          .finally(() => commit('toggleLoading', null, { root: true }));
      });
    },

    /**
     * [PUT] /admin/rooms/:roomId
     *
     * @parameters roomId: string
     * @returns Room
     *
     * Updates a Room in the storage, then
     * returns the updated Room.This Room will then be
     * saved in the local storage.
     */
    updateRoom: ({ commit }, roomData) => {
      commit('toggleLoading', null, { root: true });
      return new Promise((resolve, reject) => {
        const { id, ...room } = roomData;

        $http.put(`admin/rooms/${id}`, room)
          .then((res) => {
            commit('replaceRoom', res.data);
            resolve(res.data);
          })
          .catch((err) => reject(err))
          .finally(() => commit('toggleLoading', null, { root: true }));
      });
    },

    /**
     * Duplicates a given room
     *
     * @param { commit, dispatch } vuex
     * @param roomId string
     *
     * @returns Promise<RoomEntity>
     */
    duplicateRoom: async ({ commit, dispatch }, roomId) => {
      commit('toggleLoading', null, { root: true });
      try {
        const promises = await Promise.all([
          dispatch('fetchRoom', roomId),
          dispatch('participations/fetchParticipations', { params: { roomId, role: 'PRESENTER', fetchUser: true } }, { root: true }),
        ]);
        const [room, { data }] = promises;
        const {
          pollUrl, scope, settings, name, permissionsAttendee,
          permissionsPresenter, grantPermissionsOnEnlist,
          presenterControlUrl, baseName, videos,
        } = room;

        const newRoom = await dispatch('createRoom', {
          videos,
          pollUrl,
          settings,
          baseName,
          scopeId: scope.scopeId,
          name: `Copy of: ${name}`,
          presenterControlUrl,
          permissionsAttendee,
          permissionsPresenter,
          grantPermissionsOnEnlist,
          isDefault: false,
        });

        await Promise.all(data.map((presenter) => dispatch('participations/createParticipation', {
          role: 'PRESENTER',
          roomId: newRoom.roomId,
          nickname: presenter.nickname,
          userId: presenter.user.userId,
          permissions: newRoom.permissionsPresenter,
        }, { root: true })));

        commit('toggleLoading', null, { root: true });

        return newRoom;
      } catch (error) {
        commit('toggleLoading', null, { root: true });
        return false;
      }
    },

    /**
     * Creates a Room using the template defined by the default Room in the
     * Scope, if there is no default Room, the call will throw.
     *
     * @param { commit } vuex
     * @param { name, scopeId } DefaultRoomDto
     *
     * @returns Promise<RoomEntity>
     */
    createRoomFromDefault: async ({ commit }, roomData) => {
      commit('toggleLoading', null, { root: true });

      return new Promise((resolve, reject) => {
        $http.post('admin/rooms/create-default', { ...roomData })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => reject(err))
          .finally(() => commit('toggleLoading', null, { root: true }));
      });
    },

    /**
     * Closes a room and removes all of the Attendee Participations
     *
     * @param { commit } vuex
     * @param { string } roomId
     */
    closeRoom: (vuex, roomId) => new Promise((resolve, reject) => {
      $http.put(`admin/rooms/${roomId}/close`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    }),
  },
};

export default RoomsModule;
