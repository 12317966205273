import $http from '../helpers/api';

const AuthModule = {
  namespaced: true,

  state: () => ({
    bearer: false,
    refresh: false,
    refreshing: false,
    accessTokenExpiry: false,
    accountType: 'ADMIN',
    username: '',
  }),

  mutations: {
    removeAccessToken: (state) => { state.bearer = false; },
    setBearer: (state, bearer) => { state.bearer = bearer; },
    setTokens: (state, tokens) => {
      const { accessTokenExpiry, accessToken, refreshToken } = tokens;
      state.bearer = accessToken;
      state.refresh = refreshToken;
      state.accessTokenExpiry = accessTokenExpiry;
    },
    setRefreshing: (state, refreshing) => { state.refreshing = refreshing; },
    setAccountType: (state, accountType) => { state.accountType = accountType; },
    setUserName: (state, username) => { state.username = username; },
  },

  getters: {
    getBearerToken: (state) => state.bearer,
    getRefreshToken: (state) => state.refresh,
  },

  actions: {
    login({ state, commit }, authData) {
      return new Promise((resolve, reject) => {
        $http.post('accounts/login', authData)
          .then((res) => {
            const { accountType, username, ...tokens } = res.data;
            commit('setTokens', tokens);
            if (accountType !== state.accountType) commit('setAccountType', accountType);
            commit('setUserName', username);
            resolve(res);
          })
          .catch((err) => reject(err));
      });
    },

    refreshTokens({ state, commit }) {
      commit('toggleLoading', null, { root: true });

      return new Promise((resolve, reject) => {
        $http.get('accounts/refresh')
          .then((res) => {
            const { accountType, username, ...tokens } = res.data;
            commit('setTokens', tokens);
            if (accountType !== state.accountType) commit('setAccountType', accountType);
            commit('setUserName', username);
            resolve(tokens);
          })
          .catch((err) => reject(err))
          .finally(() => commit('toggleLoading', null, { root: true }));
      });
    },
  },
};

export default AuthModule;
