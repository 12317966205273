import store from '../store';

const { localStorage } = window;

export const userSessionExists = () => !!localStorage.getItem('user_session') || false;

export const removeSession = () => localStorage.removeItem('user_session');

export const setSession = (session, expires) => {
  localStorage.setItem('user_session', session, { expires });
};

export const checkAuth = (to, from, next) => {
  const isAuth = localStorage.getItem('user_session');

  if (to.meta.auth === null) {
    if (!isAuth) return next();
    return next({ name: from.name });
  } if (to.meta.auth === true) {
    if (isAuth) {
      store.commit('auth/setBearer', isAuth);
      return next();
    }
    return next({ name: 'Login' });
  } return next();
};

export const redirectTo = () => {
  if (userSessionExists()) {
    if (store.state.auth.accountType === 'SUPER') return 'ScopeIndex';
    return 'RoomIndex';
  }

  return 'Login';
};
