/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex from 'vuex';
import { isEmpty } from 'lodash';

import createPersistedState from 'vuex-persistedstate';
import AuthModule from './AuthModule';
import RoomWizard from './RoomWizard';
import RoomsModule from './RoomsModule';
import NavbarModule from './NavbarModule';
import UsersModule from './UsersModule';
import ParticipationsModule from './ParticipationsModule';
import AccountsModule from './AccountsModule';
import ScopesModule from './ScopesModule';
import AlertModule from './AlertModule';

const authState = createPersistedState({
  paths: ['auth', 'ScopesModule'],
});

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    loading: false,
    error: false,
    selected: {},
  },

  mutations: {
    toggleLoading: (state) => { state.loading = !state.loading; },
    setSelected: (state, selected = {}) => {
      state.selected = selected;
    },
  },

  getters: {
    activeDialog: (state) => !isEmpty(state.selected),
  },

  modules: {
    auth: AuthModule,
    navbar: NavbarModule,
    rooms: RoomsModule,
    users: UsersModule,
    wizard: RoomWizard,
    participations: ParticipationsModule,
    AccountsModule,
    ScopesModule,
    AlertModule,
  },

  plugins: [
    authState,
  ],
});

export default store;
